import * as React from 'react';
import PropTypes from 'prop-types';
import Sticky from 'react-sticky-el';
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Img from "react-cool-img";



class Project extends React.Component {
  render() {
    return (
      <section id="content">
        <div className="content-wrap">
          <div className="container">
            <div className="row gutter-50">
              <div className="col-lg-7 portfolio-single-image" data-lightbox="gallery">
                <div className="row col-mb-30">
                  <Carousel arrows={false} showThumbs={false} dynamicHeight={true}>
                    {this.props.images.map((image, index) => {
                      if (image.video) {
                        return (
                          <div key={index} >
                            <iframe width="560" height="315" src={image.src}
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen/>
                          </div>
                        )
                      }
                      return (
                        <div key={index}>
                          <Img
                              style={{ backgroundColor: "grey", width: "480", height: "320" }}
                              src={image.src}
                              alt={image.alt}
                          />
                          {/*<img src={image.src} alt={image.alt}/>*/}
                        </div>
                      )
                    })}
                  </Carousel>
                </div>
              </div>
              <div className="col-lg-5 portfolio-single-content">
                <Sticky stickyStyle={{paddingTop: "30px"}}>
                  <h2>{this.props.title}</h2>
                  <p className="op-07">{this.props.description}</p>
                  <div className="line my-5"/>
                  <ul className="portfolio-meta bottommargin">
                    <li><span><i className="icon-calendar3"/>Project Dates:</span> {this.props.completed_on}</li>
                    {this.props.client ?
                      <li><span><i className="icon-user"/>Client:</span> {this.props.client}</li> : null}
                    {this.props.link ?
                      <li><span><i className="icon-link"/>Link:</span> <a href={this.props.link}>{this.props.link}</a> </li> : null}
                  </ul>
                </Sticky>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Project.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
  completed_on: PropTypes.string.isRequired,
  client: PropTypes.string,
  tags: PropTypes.array,
  link: PropTypes.string
};


export default Project;
