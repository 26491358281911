import './App.css';
import Project from "./components/project";
import portfolio from "./portfolio.json";
import settings from "./settings.json";

function encode(data) {
  return Object.keys(data)
    .map(
      (key) =>
        encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
    )
    .join("&");
}

const handleSubmit = (event) => {
  event.preventDefault();
  const data = {}
  for (let i = 0; i < event.target.elements.length; i++) {
    data[event.target.elements[i].name] = event.target.elements[i].value;
  }
  fetch("/", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: encode({
      "form-name": event.target.getAttribute("name"),
      ...data,
    }),
  })
    .then(() => alert("Success! I'll be in touch."))
    .then(() => {
      document.getElementById("template-contactform").reset();
    })
    .catch((error) => alert(error));
};


function App() {
  return (
    <>
      <div>
        <section id="slider" className="slider-element slider-parallax min-vh-100 include-header">
          <div className="slider-inner bgimg" >
            <div className="vertical-middle text-start slider-element-fade">
              <div className="container py-5">
                <div className="emphasis-title bottommargin">
                  <div>
                    <h1 className="text-uppercase fw-bold" style={{fontSize: '52px', letterSpacing: '10px', wordSpacing: '5px'}}>Tyler Wilson</h1>
                    <span className="text-uppercase" style={{color: '#888', letterSpacing: '10px', fontSize: '18px'}}>Digital Marketing Professional</span>
                    <p className="lead topmargin" style={{maxWidth: '600px', lineHeight: '1.2', color: '#AAA'}}>Tyler Wilson is a native of Atlanta, Georgia where he was born and raised. Throughout his years, Wilson honed his skills in photography, videography, and media production by getting involved at Mount Pisgah United Methodist Church and spearheading technical production at Mount Pisgah Christian School until his High School Graduation in 2016.
                      <br/><br/>Upon starting his education at Auburn University, Wilson joined the TV Station, Radio Station, and Yearbook to begin growing his network. While nurturing those relationships, he also joined the Delta Chi Fraternity and held a variety of leadership roles including Philanthropy of the Jimmy V Foundation for Cancer Research, Involvement, Recruitment, and finally Vice-President.
                      <br/><br/>In May 2020, Wilson graduated from Auburn University with a B.A. in Media Studies, and a minor in Marketing. He remained in the city of Auburn for another year to help the growth and development of FetchMe, a local Delivery, and Takeout company.
                      <br/><br/>Now back in The Greater Atlanta Area, Wilson runs his own Digital Marketing company "Tyler Wilson Overtures", to help talented individuals and hungry organizations with their business ventures. He also works for corporate Home Depot creating media plans for prominent vendors and continues to freelance with projects ranging from weddings, music videos, and many more.</p>
                  </div>
                </div>
                <div>
                  <a className={"button button-border button-border-thin button-green"} href={"https://drive.google.com/file/d/1yao-vbsC2O3bWDDRHA8MDeecUzVXIMnm/view?usp=sharing"}>View My Resume</a>
                </div>
                <div className="topmargin">
                  <a href={settings.social_links.instagram} className="social-icon inline-block si-small si-rounded si-colored si-instagram" title="Instagram">
                    <i className="icon-instagram"/>
                    <i className="icon-instagram"/>
                  </a>
                  <a href={settings.social_links.linkedin} className="social-icon inline-block si-small si-rounded si-colored si-linkedin" title="LinkedIn">
                    <i className="icon-linkedin"/>
                    <i className="icon-linkedin"/>
                  </a>
                </div>
              </div>
            </div>
            <a href="#content" data-scrollto="#section-about" data-easing="easeInOutExpo" data-speed="1250" data-offset="70" className="one-page-arrow">
              <i className="icon-angle-down infinite animated fadeInDown"/>
            </a>

          </div>
        </section>
        {portfolio.map((proj) => {
          return <Project
            title={proj.title}
            description={proj.description}
            images={proj.images}
            completed_on={proj.completed_on}
            client={proj.client}
            tags={proj.tags}
            link={proj.link}
          />
        })}
        <section id="content">
          <div className="content-wrap">
            <div className="container clearfix">
              <div className="row gutter-40 col-mb-80">
                {/* Postcontent
						============================================= */}
                <div className="postcontent col-lg-12">
                  <h3>Send me an Email</h3>
                  <form className="row mb-0" id="template-contactform" name="template-contactform" onSubmit={handleSubmit}>
                    <div className="form-process">
                      <div className="css3-spinner">
                        <div className="css3-spinner-scaler" />
                      </div>
                    </div>
                    <div className="col-md-4 form-group">
                      <label htmlFor="template-contactform-name">Name <small>*</small></label>
                      <input type="text" id="template-contactform-name" name="template-contactform-name" defaultValue={""} className="sm-form-control required" />
                    </div>
                    <div className="col-md-4 form-group">
                      <label htmlFor="template-contactform-email">Email <small>*</small></label>
                      <input type="email" id="template-contactform-email" name="template-contactform-email" defaultValue={""} className="required email sm-form-control" />
                    </div>
                    <div className="col-md-4 form-group">
                      <label htmlFor="template-contactform-phone">Phone</label>
                      <input type="text" id="template-contactform-phone" name="template-contactform-phone" defaultValue={""} className="sm-form-control"  />
                    </div>
                    <div className="w-100" />
                    <div className="col-12 form-group">
                      <label htmlFor="template-contactform-message">Message <small>*</small></label>
                      <textarea className="required sm-form-control" id="template-contactform-message" name="template-contactform-message" rows={6} cols={30} defaultValue={""} />
                    </div>
                    <div className="col-12 form-group d-none">
                      <input type="text" id="bot-field" name="bot-field" className="sm-form-control" />
                    </div>
                    <div className="col-12 form-group">
                      <button className="button button-3d m-0" type="submit" id="template-contactform-submit" name="template-contactform-submit" value="submit">Send Message</button>
                    </div>
                    <input type="hidden" name="prefix" defaultValue="template-contactform-" />
                  </form>
                </div>{/* .postcontent end */}
              </div>
            </div>
          </div>
        </section>{/* #content end */}

        <footer id="footer" className="bg-light border-width-1 border-f5">
          <div className="container">
            {/* Footer Widgets
				============================================= */}
            <div className="footer-widgets-wrap">
              <div className="row justify-content-center text-center py-sm-5">
                <div className="col-12 mb-4">
                  <div className="widget clearfix">
                    <a href="/"><h1>Tyler Wilson</h1></a>
                    <span className="tetx-uppercase op-05 d-block">Digital Marketing Professional</span>
                  </div>
                </div>
                <div className="clear" />
                <div className="col-12 mt-5">
                  {/*<div className="widget">*/}
                  {/*  <ul className="list-inline m-0">*/}
                  {/*    <li className="list-inline-item mx-lg-3 my-lg-0 m-3"><a className="text-dark h-text-danger" href="#"><u>Documentation</u></a></li>*/}
                  {/*    <li className="list-inline-item mx-lg-3 my-lg-0 m-3"><a className="text-dark h-text-danger" href="#"><u>Feedback</u></a></li>*/}
                  {/*    <li className="list-inline-item mx-lg-3 my-lg-0 m-3"><a className="text-dark h-text-danger" href="#"><u>Plugins</u></a></li>*/}
                  {/*    <li className="list-inline-item mx-lg-3 my-lg-0 m-3"><a className="text-dark h-text-danger" href="#"><u>Support Forums</u></a></li>*/}
                  {/*    <li className="list-inline-item mx-lg-3 my-lg-0 m-3"><a className="text-dark h-text-danger" href="#"><u>Themes</u></a></li>*/}
                  {/*    <li className="list-inline-item mx-lg-3 my-lg-0 m-3"><a className="text-dark h-text-danger" href="#"><u>Canvas Blog</u></a></li>*/}
                  {/*  </ul>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>{/* .footer-widgets-wrap end */}
          </div>
          {/* Copyrights
			============================================= */}
          <div id="copyrights" className="bg-white">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <div className="d-flex justify-content-center mb-3">
                    <a href={settings.social_links.instagram} className="social-icon si-small si-colored si-rounded si-instagram">
                      <i className="icon-instagram" />
                      <i className="icon-instagram" />
                    </a>
                    <a href={settings.social_links.linkedin} className="social-icon si-small si-colored si-rounded si-linkedin">
                      <i className="icon-linkedin" />
                      <i className="icon-linkedin" />
                    </a>
                  </div>
                  <span className="text-black-50">© 2020 All Rights Reserved by Tyler Wilson.</span>
                </div>
              </div>
            </div>
          </div>{/* #copyrights end */}
        </footer>{/* #footer end */}
      </div>
      <div id="gotoTop" className="icon-angle-up"/>
    </>
  );
}

export default App;
